.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #f3f3f3; */
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.header-user {
    font-size: 16px;
    color: #666;
}
