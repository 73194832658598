.school-registration-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.school-registration-form h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.school-registration-form label {
    display: block;
    margin-bottom: 10px;
    color: #666;
}

.school-registration-form label span {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: start;
}

.school-registration-form input[type="text"],
.school-registration-form input[type="number"],
.school-registration-form select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.school-registration-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #5c6bc0;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.school-registration-form button:hover {
    background-color: #3f51b5;
}

.school-registration-form button:disabled {
    background-color: gray;
    cursor: not-allowed;
}
